import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    strict: debug,
    state: {
        menuOn: false,
        isMobile: false,
        isTablet: false,
        isScreen: false
    },
    mutations: {
        setMenuVisibility(state, isVisible) {
            state.menuOn = isVisible;
        },
        setIsMobile(state, isMobile) {
            state.isMobile = isMobile;
            state.isScreen = !(state.isMobile || state.isTablet);
        },
        setIsTablet(state, isTablet) {
            state.isTablet = isTablet;
            state.isScreen = !(state.isMobile || state.isTablet);
        }
    }
});
