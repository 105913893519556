<template>
    <button class="btn flex justify-center items-center" @click="onClick" :class="cssClasses">
        <span class="mr-5 pt-[3px] min-w-fit">{{ label }}</span>
        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M7.3 14.7a1 1 0 0 1 0-1.4l3.29-3.3-3.3-3.3a1 1 0 0 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42 0z" clip-rule="evenodd"/>
        </svg>
    </button>
</template>

<script>
export default {
    name: "YellowButton",
    props: {
        label: { type: String, required: true },
        black: { type: Boolean, default: false },
        white: { type: Boolean, default: false },
        disable: { type: Boolean, default: false }
    },
    computed: {
        cssClasses() {
            const out = [];
            if (this.disable) {
                out.push("opacity-50", "cursor-not-allowed");
            } else {
                out.push("cursor-pointer");
            }

            if (this.black || this.white) {
                out.push(this.black ? "btn__black" : undefined, this.white ? "btn__white" : undefined);
            } else {
                out.push("btn__black");
            }
            return out;
        }
    },
    methods: {
        onClick() {
            if (!this.disable) {
                this.$emit("click");
            }
        }
    }
};
</script>
