<template>
    <footer class="w-full fluid-page md:pt-44 xs:pt-8 sm:pt-12 pb-20 bg-yt__gray">
        <div class="flex flex-col md:flex-row justify-between items-start gap-10">
            <div class="md:w-3/12 sm:w-full flex-none">
                <img :src="logo" alt="alt" class="md:w-[238px] sm:w-[249px] w-[154px] object-contain">
            </div>
            <div class="flex-1 flex flex-col xs:w-full sm:w-auto">
                <div class="flex flex-row gap-y-10 gap-x-10 justify-between">
                    <div class="w-[30%] flex flex-col min-w-fit">
                        <router-link :to="page.to" v-for="(page, index) in pages" :key="index">
                            <p class="text-base md:pb-[1em] sm:pb-[2.5em] xs:pb-[1em]">{{ page.name }}</p>
                        </router-link>
                    </div>
                    <div class="md:w-[40%] sm:w-fit hidden sm:flex flex-col justify-between min-w-min">
                        <div class="flex flex-col items-start gap-4 min-w-min">
                            <yellow-button label="Contact" @click="goToContact"></yellow-button>
                        </div>
                        <div class="hidden sm:block md:hidden">
                            <router-link to="/cookiePolicy">
                                <p class="text-base pb-[2.5em] opacity-50">COOKIE POLICY</p>
                            </router-link>
                            <router-link to="/privacyPolicy">
                                <p class="text-base pb-[2.5em] opacity-50">PRIVACY POLICY</p>
                            </router-link>
                        </div>
                    </div>
                    <div class="flex w-6 flex-wrap justify-end items-start content-start gap-x-10 md:gap-y-5 sm:gap-y-10 xs:gap-y-5">
                        <svg class="w-6 h-6 cursor-pointer" viewBox="0 0 24 24" @click="goToLinkedIn" @keyup="goToLinkedIn">
                            <path fill="currentColor" d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z" />
                        </svg>
                        <!--<svg class="w-6 cursor-pointer flex-grow-0 flex-shrink basis-6" viewBox="0 0 448 512">-->
                        <!--    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>-->
                        <!--</svg>-->
                        <!--<svg class="w-6 cursor-pointer flex-grow-0 flex-shrink basis-6" viewBox="0 0 512 512">-->
                        <!--    <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/>-->
                        <!--</svg>-->
                        <!--<svg class="w-6 cursor-pointer flex-grow-0 flex-shrink basis-6" viewBox="0 0 512 512">-->
                        <!--    <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>-->
                        <!--</svg>-->
                        <!--<svg class="w-6 cursor-pointer flex-grow-0 flex-shrink basis-6" viewBox="0 0 512 512">-->
                        <!--    <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM57.71 192.1L67.07 209.4C75.36 223.9 88.99 234.6 105.1 239.2L162.1 255.7C180.2 260.6 192 276.3 192 294.2V334.1C192 345.1 198.2 355.1 208 359.1C217.8 364.9 224 374.9 224 385.9V424.9C224 440.5 238.9 451.7 253.9 447.4C270.1 442.8 282.5 429.1 286.6 413.7L289.4 402.5C293.6 385.6 304.6 371.1 319.7 362.4L327.8 357.8C342.8 349.3 352 333.4 352 316.1V307.9C352 295.1 346.9 282.9 337.9 273.9L334.1 270.1C325.1 261.1 312.8 255.1 300.1 255.1H256.1C245.9 255.1 234.9 253.1 225.2 247.6L190.7 227.8C186.4 225.4 183.1 221.4 181.6 216.7C178.4 207.1 182.7 196.7 191.7 192.1L197.7 189.2C204.3 185.9 211.9 185.3 218.1 187.7L242.2 195.4C250.3 198.1 259.3 195 264.1 187.9C268.8 180.8 268.3 171.5 262.9 165L249.3 148.8C239.3 136.8 239.4 119.3 249.6 107.5L265.3 89.12C274.1 78.85 275.5 64.16 268.8 52.42L266.4 48.26C262.1 48.09 259.5 48 256 48C163.1 48 84.4 108.9 57.71 192.1L57.71 192.1zM437.6 154.5L412 164.8C396.3 171.1 388.2 188.5 393.5 204.6L410.4 255.3C413.9 265.7 422.4 273.6 433 276.3L462.2 283.5C463.4 274.5 464 265.3 464 256C464 219.2 454.4 184.6 437.6 154.5H437.6z"/>-->
                        <!--</svg>-->
                    </div>
                </div>
                <div class="md:block hidden">
                    <router-link to="/cookiePolicy">
                        <p class="text-base pb-[1em] pt-[1em] opacity-50">COOKIE POLICY</p>
                    </router-link>
                    <router-link to="/privacyPolicy">
                        <p class="text-base pb-[2em] opacity-50">PRIVACY POLICY</p>
                    </router-link>
                </div>
                <!--<p class="text-base opacity-50 md:pt-0 sm:pt-10 hidden sm:block">Yellow Tree (Gibraltar) Ltd & Yellow Tree Real Estate (Gibraltar) Ltd are companies registered and regulated under the laws of Gibraltar.</p>-->
                <!--<p class="text-base opacity-50 pt-[1em] hidden sm:block">Registered address: 57/63 Line Wall Road, Gibraltar, GX1 1AA</p>-->
            </div>
        </div>
        <p class="text-base md:pt-[2em] sm:pt-20 xs:pt-[2em]">©2024 YELLOW TREE</p>
    </footer>
</template>

<script>
import YellowButton from "@/components/YellowButton.vue";
// import logo from "@/assets/images/Yellow-Tree-Logo-retinax4.png";
// import logo from "@/assets/images/YellowTree_PrimaryLogo_transparent.png";
import logo from "@/assets/images/Logo/Primary-stroke-and-fill.png";

export default {
    name: "BaseFooter",
    components: { YellowButton },
    data() {
        return {
            logo,
            pages: [
                // { name: "ABOUT US", to: "/about" },
                { name: "ASSET INVESTMENTS", to: "/assetInvestments" },
                // { name: "SERVICES", to: "/services" },
                { name: "OUR TEAM", to: "/team" },
                { name: "CAREERS", to: "/careers" }
            ]

        };
    },
    methods: {
        goToLinkedIn() {
            window.open("https://www.linkedin.com/company/yellow-tree-group-real-estate-investment-management/");
        }
    }
};
</script>

<style scoped lang="scss">

</style>
