<template>
    <div class="w-full relative">
        <section class="relative">
            <picture>
                <source media="(min-width: 640px)" :srcset="img.tablet.tLuke">
                <img :src="img.mobile.mLuke" alt="Logo Top" class="absolute inset-0 w-full h-full object-center object-cover">
            </picture>
            <div class="py-28 relative sm:pt-[19rem] md:w-[58%] md:pt-32">
                <div class="py-12 md:pr-5 flex flex-col items-center md:items-start content-blur-opacity-85 md:rounded-[18px]">
                    <h1 :class="{'fluid-padding__left': isScreen}">Yellow Tree Group</h1>
                    <h3 class="fluid-page md:pr-0 text-center md:text-left pb-6 max-w-[35ch] md:max-w-[60ch]">pursues a buy and hold strategy in commercial and residential real estate in capital cities.</h3>
                    <!--<div :class="{'fluid-padding__left': isScreen}">-->
                    <!--    <yellow-button label="Explore" @click="goToAssets"></yellow-button>-->
                    <!--</div>-->
                </div>
            </div>
        </section>

        <section class="bg-yt__yellow">
            <div class="flex justify-center">
                <div class="max-w-[69ch] py-16 px-8">
                    <h3 class="pro-bold">Yellow Tree is a Gibraltar-based group dedicated to the ownership, development and management of commercial and residential real estate assets across capital cities, applying a bespoke investment strategy to each geographic region. We focus on developed markets across North America and Western Europe and developing markets across the Middle East and Eastern Europe.</h3>
                    <h3 class="pro-bold mt-[2rem]">We currently own and manage over 1,000,000 sq. ft. across multiple sector types, including offices, logistics, warehouses, residential and major mixed-use schemes.</h3>
                </div>
            </div>
        </section>

        <!--<section class="bg-yt__gray">-->
        <!--    <div class="relative pt-16 sm:pt-24 text-center z-10">-->
        <!--        <p class="text-yt__yellow uppercase pb-4">What we do</p>-->
        <!--        <h1 class="px-6 block sm:hidden">Investment, Development and Management of Real Estate</h1>-->
        <!--        <h1 class="px-6 hidden sm:block">Investment, Development<br>and Management<br>of Real Estate</h1>-->
        <!--    </div>-->
        <!--</section>-->

        <!--<section class="bg-yt__gray">-->
        <!--    <div class="relative">-->
        <!--        <div class="w-full h-full block sm:hidden fluid-page">-->
        <!--            <img :src="img.desk.dPicture1" alt="Logo Top" class="block object-center object-cover">-->
        <!--        </div>-->
        <!--        <div class="hidden sm:block absolute block inset-0 sm:mb-5 md:mb-0">-->
        <!--            <div class="sm:relative sm:w-1/2 md:w-[37%] h-full" :class="{'fluid-padding__left': isScreen}">-->
        <!--                <picture>-->
        <!--                    <source media="(min-width: 640px)" :srcset="img.tablet.tPicture1">-->
        <!--                    <img :src="img.mobile.mPicture1" alt="Logo Top" class="absolute w-full h-full object-center object-cover">-->
        <!--                </picture>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--        <div class="sm:pt-10 relative z-10 md:pb-32 md:pt-0">-->
        <!--            <div class="flex justify-end">-->
        <!--                <div class="content-blur w-full sm:w-[60%] fluid-page rounded-[18px]">-->
        <!--                    <article class="pt-14 sm:pt-10">-->
        <!--                        <p class="pb-4">Yellow Tree is a Gibraltar-based group dedicated to the ownership, development and management of commercial and residential real estate assets across capital cities in Europe, applying a bespoke investment strategy to each geographic region. We focus on developed and developing markets in Europe.</p>-->
        <!--                        <p> We currently own and manage over 1,000,000 sq. ft. across multiple sector types, including offices, logistics, warehouses, residential and major mixed-use schemes. </p>-->
        <!--                        &lt;!&ndash;<p class="pb-4">Yellow Tree is a Gibraltar based group focusing on the ownership, development and management of real estate assets across Europe applying the investment strategy according to each geographic region.</p>&ndash;&gt;-->
        <!--                        &lt;!&ndash;<p>We are a vertically integrated company that controls the entire supply chain of the real estate investment and management.</p>&ndash;&gt;-->
        <!--                    </article>-->
        <!--                    &lt;!&ndash;<div class="flex justify-center sm:justify-start my-10 sm:mb-16">&ndash;&gt;-->
        <!--                    &lt;!&ndash;    <yellow-button label="Explore" @click="goToAbout"></yellow-button>&ndash;&gt;-->
        <!--                    &lt;!&ndash;</div>&ndash;&gt;-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--    </div>-->
        <!--</section>-->

        <!--<section class="hidden">-->
        <!--    <div class="relative pt-16 sm:pt-24 text-center z-10">-->
        <!--        <p class="text-yt__yellow uppercase pb-3">Our services</p>-->
        <!--        <h1 class="px-6">We deliver value to our customers</h1>-->
        <!--        <p class="fluid-page py-12 text-left sm:text-center">Yellow Tree Services is part of Yellow Tree Group and is the vertically integrated service provider for facility and property management activities across all commercial real estate owned by the Group.</p>-->
        <!--    </div>-->
        <!--    <div class="flex flex-col gap-y-9" :class="{'fluid-page': isMobile || isScreen}">-->
        <!--        <div class="relative">-->
        <!--            <picture>-->
        <!--                <source media="(min-width: 640px)" :srcset="img.tablet.tGroup1">-->
        <!--                <img :src="img.mobile.mGroup1" alt="Logo Top" class="w-full h-auto object-center object-cover">-->
        <!--            </picture>-->
        <!--            <div class="absolute inset-0 px-5 py-7">-->
        <!--                <div class="flex flex-col justify-end w-full h-full gap-y-12">-->
        <!--                    <div class="text-white">-->
        <!--                        <p class="text-xs sm:text-[36px] uppercase pb-4">We offer</p>-->
        <!--                        <h2 class="block sm:hidden">Facility Management</h2>-->
        <!--                        <h1 class="hidden sm:block">Facility Management</h1>-->
        <!--                    </div>-->
        <!--                    <div class="flex justify-start">-->
        <!--                        <yellow-button white label="Explore" @click="goToAssets"></yellow-button>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--        <div class="relative">-->
        <!--            <picture>-->
        <!--                <source media="(min-width: 640px)" :srcset="img.tablet.tGroup2">-->
        <!--                <img :src="img.mobile.mGroup2" alt="Logo Top" class="w-full h-auto object-center object-cover">-->
        <!--            </picture>-->
        <!--            <div class="absolute inset-0 px-5 py-7">-->
        <!--                <div class="flex flex-col justify-end w-full h-full gap-y-12">-->
        <!--                    <div class="text-white">-->
        <!--                        <p class="text-xs sm:text-[36px] uppercase pb-4">We offer</p>-->
        <!--                        <h2 class="block sm:hidden">Property Management</h2>-->
        <!--                        <h1 class="hidden sm:block">Property Management</h1>-->
        <!--                    </div>-->
        <!--                    <div class="flex justify-start">-->
        <!--                        <yellow-button white label="Explore" @click="goToServices"></yellow-button>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--    </div>-->
        <!--</section>-->

        <section>
            <div class="relative pt-16 sm:pt-24 text-center z-10">
                <p class="text-yt__yellow uppercase pb-3">About us</p>
                <h1 class="px-6 pb-12">Our Purpose</h1>
            </div>
        </section>

        <section class="md:block hidden relative">
            <div class="fluid-margin__left relative pb-52">
                <div class="mr-[11.6%] relative z-10">
                    <div class="w-[62%] pt-14 pb-32 relative z-10">
                        <div class="rounded-[18px] content-blur">
                            <div class="max-w-[47ch] md:pt-10 md:pr-5">
                                <div class="pb-4">
                                    <p class="pt-6">Our purpose is to create long-lasting value conservation for our shareholders by acquiring and holding commercial and residential real estate assets in capital cities.</p>
                                </div>
                                <h2><span class="highlight">We aim to:&nbsp;</span></h2>
                                <div class="pb-6 pl-4 pt-4 flex flex-col gap-y-1">
                                    <div class="flex content-start">
                                        <p class="list-bullet mr-5">●</p>
                                        <p>Invest exclusively in projects with <span class="font-bold">long-term financial and environmental sustainability;</span></p>
                                    </div>
                                    <div class="flex content-start">
                                        <p class="list-bullet mr-5">●</p>
                                        <p><span class="font-bold">Provide a high-quality product and service</span> to our tenants at market prices;</p>
                                    </div>
                                    <div class="flex content-start">
                                        <p class="list-bullet mr-5">●</p>
                                        <p><span class="font-bold">Create a respectable and reliable reputation</span> with agents, brokers and other third-party stakeholders;</p>
                                    </div>
                                    <div class="flex content-start">
                                        <p class="list-bullet mr-5">●</p>
                                        <p><span class="font-bold">Have a positive impact</span> on the communities where we invest;</p>
                                    </div>
                                    <div class="flex content-start">
                                        <p class="list-bullet mr-5">●</p>
                                        <p><span class="font-bold">Create stable and opportunity-driven workplaces</span> across all our offices.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="absolute top-0 bottom-0 right-0 left-[54%] bg-yt__yellow z-0">
                        <img :src="img.desk.dSales" alt="sales" class="w-full h-full object-cover">
                    </div>
                </div>
            </div>
            <div class="absolute left-0 bottom-0 right-[30.5%] bg-yt__yellow">
                <div class="fluid-margin__left py-20 max-w-[40ch]">
                    <h3 class="pro-bold">Committed to delivering on our promises, we believe we can achieve our short-term and long-term objectives.</h3>
                </div>
            </div>
        </section>

        <section class="md:hidden">
            <section class="fluid-page pb-28 sm:pb-10 content-blur relative z-20 rounded-[18px] ml-px">
                <div class="max-w-[47ch] md:pt-10 md:pr-5">
                    <div class="pb-4">
                        <p class="pt-6">Our purpose is to create long-lasting value conservation for our shareholders by acquiring and holding commercial and residential real estate assets in capital cities.</p>
                    </div>
                    <h2><span class="highlight">We aim to:&nbsp;</span></h2>
                    <div class="pb-6 pl-4 pt-4 flex flex-col gap-y-1">
                        <div class="flex content-start">
                            <p class="list-bullet mr-5">●</p>
                            <p>Invest exclusively in projects with <span class="font-bold">long-term financial and environmental sustainability;</span></p>
                        </div>
                        <div class="flex content-start">
                            <p class="list-bullet mr-5">●</p>
                            <p><span class="font-bold">Provide a high-quality product and service</span> to our tenants at market prices;</p>
                        </div>
                        <div class="flex content-start">
                            <p class="list-bullet mr-5">●</p>
                            <p><span class="font-bold">Create a respectable and reliable reputation</span> with agents, brokers and other third-party stakeholders;</p>
                        </div>
                        <div class="flex content-start">
                            <p class="list-bullet mr-5">●</p>
                            <p><span class="font-bold">Have a positive impact</span> on the communities where we invest;</p>
                        </div>
                        <div class="flex content-start">
                            <p class="list-bullet mr-5">●</p>
                            <p><span class="font-bold">Create stable and opportunity-driven workplaces</span> across all our offices.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="relative -mt-14 sm:-mt-44">
                <div class="pl-24 pr-0 relative pb-48 sm:py-44 z-10">
                    <picture>
                        <source media="(min-width: 640px)" :srcset="img.tablet.tLinkedin">
                        <img :src="img.mobile.mLinkedin" alt="Logo Top" class="w-full h-auto object-center object-cover">
                    </picture>
                </div>
                <div class="absolute bottom-0 left-0 right-0 bg-yt__yellow fluid-page mr-0" :class="{'fluid-margin__right': isTablet}">
                    <h4 class="py-20">Committed to delivering on our promises, we believe we can achieve our short-term and long-term objectives.</h4>
                </div>
            </section>
        </section>

        <section class="relative">
            <div class="relative pt-16 pb-32 sm:pt-24 text-center z-10">
                <h1 class="pb-16">What We Value</h1>
                <div class="flex fluid-margin__left fluid-margin__right flex-wrap pb-10 gap-y-8 z-10 relative bg-yt__white rounded-br-lg mb-64">
                    <!--<div class="sm:bg-red-300 xs:bg-blue-300 md:bg-green-300 xl:bg-yellow-300">asdasd</div>-->
                    <div class="md:w-1/3 sm:w-1/2 w-full sm:pr-8 pr-0">
                        <div class="flex flex-col items-start text-left">
                            <h3 class="pro-bold pb-6"><span class="highlight">Our reputation&nbsp;</span></h3>
                            <p>We will never risk our reputation for short or long-term gain.</p>
                        </div>
                    </div>
                    <div class="md:w-1/3 sm:w-1/2 w-full sm:pr-8 pr-0">
                        <div class="flex flex-col items-start text-left">
                            <h3 class="pro-bold pb-6"><span class="highlight">Our tenants&nbsp;</span></h3>
                            <p>Our tenants are the most important thing. Because of them we are in business.</p>
                        </div>
                    </div>
                    <div class="md:w-1/3 sm:w-1/2 w-full sm:pr-8 pr-0">
                        <div class="flex flex-col items-start text-left">
                            <h3 class="pro-bold pb-6"><span class="highlight">Reliability&nbsp;</span></h3>
                            <p>We strive to always make good on our promises and to build a solid track record of doing so.</p>
                        </div>
                    </div>
                    <div class="md:w-1/3 sm:w-1/2 w-full sm:pr-8 pr-0">
                        <div class="flex flex-col items-start text-left">
                            <h3 class="pro-bold pb-6"><span class="highlight">Honesty&nbsp;</span></h3>
                            <p>We build trust by being honest and authentic.</p>
                        </div>
                    </div>
                    <div class="md:w-1/3 sm:w-1/2 w-full sm:pr-8 pr-0">
                        <div class="flex flex-col items-start text-left">
                            <h3 class="pro-bold pb-6"><span class="highlight">Teamwork&nbsp;</span></h3>
                            <p>We embrace differences; each of our team members has unique skills and experience that together create value.</p>
                        </div>
                    </div>
                    <div class="md:w-1/3 sm:w-1/2 w-full sm:pr-8 pr-0">
                        <div class="flex flex-col items-start text-left">
                            <h3 class="pro-bold pb-6"><span class="highlight">Individual progress&nbsp;</span></h3>
                            <p>We support professional development and recognise and reward exceptional talent.</p>
                        </div>
                    </div>
                    <div class="md:w-1/3 sm:w-1/2 w-full sm:pr-8 pr-0">
                        <div class="flex flex-col items-start text-left">
                            <h3 class="pro-bold pb-6"><span class="highlight">Quality&nbsp;</span></h3>
                            <p>We will never jeopardise the quality of our brand and the products and services we offer.</p>
                        </div>
                    </div>
                </div>
                <div class="absolute bottom-0 right-0 top-[33%] md:left-[44%] sm:left-[22%] left-0 bg-yt__yellow">
                    <picture>
                        <source media="(min-width: 640px)" :srcset="img.tablet.tPicture1">
                        <img :src="img.mobile.mPicture1" alt="Logo Top" class="absolute w-full h-full object-center object-cover">
                    </picture>
                </div>
            </div>
        </section>

        <!--<div class="absolute bottom-14 w-full z-50 fluid-padding__right text-yt__white">-->
        <!--    <svg class="w-10 h-10 cursor-pointer float-right" fill="none" stroke="currentColor" viewBox="0 0 24 24" @click="onClickGoTop" @keydown="onClickGoTop">-->
        <!--        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m5 15 7-7 7 7"/>-->
        <!--    </svg>-->
        <!--</div>-->
    </div>
</template>

<script>
import image1 from "@/assets/images/Desktop/2x/Desktop_2x_compressjpeg/1_Home/luke-van-zyl-KWDBNXK_v7c-unsplash.jpg";

import tLuke from "@/assets/images/Tablet/2x/2x (compressed)/pg 1/luke-van-zyl-KWDBNXK_v7c-unsplash-min.png";
import mLuke from "@/assets/images/Mobile/2x/Mobile_2x_compressjpeg/1_Home/luke-van-zyl-KWDBNXK_v7c-unsplash.jpg";
import mPicture1 from "@/assets/images/Mobile/2x/Mobile_2x_compressjpeg/1_Home/Picture1.jpg";
import tPicture1 from "@/assets/images/Tablet/2x/2x (compressed)/pg 1/Picture1-min.png";
import dPicture1 from "@/assets/images/Desktop/2x/Desktop_2x_compressjpeg/1_Home/Picture1.jpg";
import dSales from "@/assets/images/Desktop/2x/Desktop_2x_compressjpeg/1_Home/linkedin-sales-solutions-nISqmehpBQk-unsplash.jpg";
import mGroup1 from "@/assets/images/Mobile/2x/Mobile_2x_compressjpeg/1_Home/Group 804.jpg";
import tGroup1 from "@/assets/images/Tablet/2x/2x (compressed)/pg 1/Group 809-min.png";
import mGroup2 from "@/assets/images/Mobile/2x/Mobile_2x_compressjpeg/1_Home/Group 805.jpg";
import tGroup2 from "@/assets/images/Tablet/2x/2x (compressed)/pg 1/Group 810-min.png";
import mLinkedin from "@/assets/images/Mobile/2x/Mobile_2x_compressjpeg/1_Home/linkedin-sales-solutions-nISqmehpBQk-unsplash.jpg";
import tLinkedin from "@/assets/images/Tablet/2x/2x (compressed)/pg 1/linkedin-sales-solutions-nISqmehpBQk-unsplash-min.png";

export default {
    name: "Home",
    data() {
        return {
            img: {
                desk: { dPicture1, dSales },
                tablet: { tLuke, tPicture1, tGroup1, tGroup2, tLinkedin },
                mobile: { mLuke, mPicture1, mGroup1, mGroup2, mLinkedin },
                image1
            }
        };
    },
    components: { },
    methods: {
        goToAssets() {
            this.$router.push({ name: "AssetInvestments" });
        },
        goToServices() {
            this.$router.push({ name: "Services" });
        },
        goToAbout() {
            this.$router.push({ name: "About" });
        }
    }
};
</script>

<style lang="scss">
</style>
