<template>
    <header class="header">
        <div class="header__content fluid-page flex h-full">
            <div class="w-full h-full flex items-center justify-between gap-5" ref="nav">
                <router-link to="/" class="min-w-fit">
                    <div class="header__logo">
                        <img :src="logo" alt="alt" class="object-contain">
                    </div>
                </router-link>
                <nav class="w-full flex items-center justify-evenly px-3 content-center overflow-hidden" v-if="!showMobileMenu">
                    <router-link class="uppercase text-center shrink-0" :to="link.to" v-for="(link, idx) in deskLinks" :key="idx" exact-active-class="selected">
                        {{ link.label }}
                    </router-link>
                </nav>
                <yellow-button class="flex min-w-fit" black label="Contact" v-if="!showMobileMenu" @click="goToContact"></yellow-button>
                <button class="focus:outline-none focus:shadow-outline text-yt__dark" v-if="showMobileMenu" @click="onMenuClick">
                    <svg class="w-11 h-11" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path v-if="showMenu" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 18 6M6 6l12 12"/>
                        <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" transform ="rotate(-180 12 12)"/>
                    </svg>
                </button>
            </div>
        </div>
        <div class="header__menu" v-if="showMenu">
            <div class="w-full h-full z-50 flex flex-col justify-between bg-yt__gray">
                <div class="flex flex-col justify-center items-center gap-y-5 mt-16">
                    <router-link class="link pro-regular text-yt__black" v-for="(route, index) in mobileLinks" exact-active-class="selected" :to="route.to" :key="index">
                        {{route.label}}
                    </router-link>
                </div>
                <div class="flex flex-col gap-y-3 mb-10 items-center">
                    <div class="pro-regular text-yt__black text-base">
                        <router-link class="text-yt__black transition opacity-50 hover:opacity-100" to="/cookiePolicy">Cookie Policy</router-link>
                    </div>
                    <div class="pro-regular text-yt__black text-base">
                        <router-link class="text-yt__black transition opacity-50 hover:opacity-100" to="/privacyPolicy">Privacy Policy</router-link>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
// import logo from "@/assets/images/Yellow-Tree-Logo-retinax4.png";
// import logo from "@/assets/images/YellowTree_PrimaryLogo_transparent.png";
import logo from "@/assets/images/Logo/Primary-stroke-and-fill.png";
import { mapState, mapMutations } from "vuex";
import YellowButton from "@/components/YellowButton.vue";

let resizeObserver;

export default {
    name: "BaseHeader",
    components: { YellowButton },
    data() {
        return {
            logo,
            noMenuSpace: false,
            links: [
                { to: "/", label: "Home", desk: false, mobile: false },
                { to: "/", label: "ABOUT US", desk: true, mobile: true },
                { to: "/assetInvestments", label: "ASSET INVESTMENTS", desk: true, mobile: true },
                { to: "/team", label: "TEAM", desk: true, mobile: true },
                { to: "/careers", label: "CAREERS", desk: true, mobile: true },
                { to: "/contact", label: "Contact", desk: false, mobile: true }
                // { to: "/services", label: "YT SERVICES", main: true },
                // { to: "/", label: "Home" },
                // { to: "/contact", label: "Home" },
                // { to: "/cookiePolicy", label: "Home" },
                // { to: "/privacyPolicy", label: "Home" }
            ]
        };
    },
    computed: {
        ...mapState({
            showMenu: "menuOn"
        }),
        showMobileMenu() {
            const show = this.noMenuSpace || this.isMobile || this.isTablet;
            if (!show) {
                this.setMenuState(false);
            }
            return show;
        },
        deskLinks() {
            return this.links.filter((item) => item.desk);
        },
        mobileLinks() {
            return this.links.filter((item) => item.mobile);
        }
    },
    methods: {
        ...mapMutations({
            setMenuState: "setMenuVisibility"
        }),
        onMenuClick() {
            this.setMenuState(!this.showMenu);
        }
    },
    mounted() {
        resizeObserver = new ResizeObserver((entries) => {
            const navEntry = entries[0].target;
            this.noMenuSpace = navEntry.clientWidth < 1100;
        });

        resizeObserver.observe(this.$refs.nav);
    },
    beforeDestroy() {
        resizeObserver.unobserve(this.$refs.nav);
    }
};
</script>

<style scoped lang="scss">
    header {
        height: var(--header__height);
    }

    .header__logo {
            width: clamp(156px, calc(9.75rem + (238 - 156) * ((100vw - 768px) / (1920 - 768))), 238px);
    }

    .header__menu {
        height: calc(100vh - var(--header__height));
    }
    nav > a {
        font-family: "myriadpro-regular", serif;
        font-size: 24px;
        color: var(--main-yt-black);
        opacity: .5;
        &.selected {
            font-family: "myriadpro-bold", serif;
            opacity: 1;
        }
    }

    .link {
        @apply transition opacity-50 hover:opacity-100 uppercase;
        font-size: 24px;
        opacity: 0.5;
        &.selected {
            opacity: 1;
        }
    }

</style>
