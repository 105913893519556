<template>
    <div id="app">
        <base-header></base-header>
        <main class="content mx-auto w-full" v-if="!menu">
            <router-view/>
            <base-footer></base-footer>
        </main>
    </div>
</template>

<script>
import { mapState } from "vuex";

import BaseHeader from "@/components/BaseHeader.vue";
import BaseFooter from "@/components/BaseFooter.vue";

const mqMobile = window.matchMedia("(max-width: 639px)");
const mqTablet = window.matchMedia("(min-width: 640px) and (max-width: 767px)");

export default {
    components: { BaseHeader, BaseFooter },
    computed: {
        ...mapState({ menu: "menuOn" })
    },
    watch: {
        $route() {
            this.$store.commit("setMenuVisibility", false);
        }
    },
    methods: {
        updateMobile(ev) {
            this.$store.commit("setIsMobile", ev.matches);
        },
        updateTablet(ev) {
            this.$store.commit("setIsTablet", ev.matches);
        }
    },
    beforeMount() {
        mqMobile.onchange = this.updateMobile;
        mqTablet.onchange = this.updateTablet;

        this.updateMobile(mqMobile);
        this.updateTablet(mqTablet);
    },
    beforeDestroy() {
        mqMobile.onchange = null;
        mqTablet.onchange = null;
    }
};
</script>

<style lang="scss">
    #app {
        display: grid;
        height: 100vh;
        grid-template-rows: auto 1fr;
    }

    .content {
        overflow: auto;
        max-width: 1920px;
        min-width: 390px;
    }
</style>
