/* eslint-disable
    no-param-reassign, no-bitwise, no-mixed-operators, implicit-arrow-linebreak, arrow-body-style
*/
// const defaultOptions = {};

import { mapState } from "vuex";

export default {
    // install(Vue, options) {
    install(Vue) {
        // const userOptions = { ...defaultOptions, ...options };

        Vue.mixin({
            computed: {
                ...mapState(["isMobile", "isTablet", "isScreen"])
            },
            methods: {
                onClickGoTop() {
                    this.$emit("goYellowTop");
                },
                goToContact() {
                    this.$router.push({ name: "Contact" });
                }
            }
        });

        Vue.prototype.$uuid = () => {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
        };
    }
};
