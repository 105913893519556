/* eslint-disable global-require */

import Vue from "vue";
import store from "@/store";
import Commons from "@/plugins/commons";
import { Icon } from "leaflet";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import App from "./App.vue";
import router from "./router";
// import 'vue-toast-notification/dist/theme-default.css';

import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import "@/assets/styles/index.css";

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

Vue.config.productionTip = false;
Vue.use(Commons);
Vue.use(VueToast);

new Vue({
    store,
    router,
    render: (h) => h(App)
}).$mount("#app");
